import { Container, Section } from '@components/common'
import Card from '@mrs-ui/Card'
import Icon from '@mrs-ui/Icon'
import Tag from '@mrs-ui/Tag'
import React from 'react'

const Solutions = () => {
  return (
    <Section>
      <Container>
        <div className="md:w-2/3">
          <h2>Deploy innovative, results-oriented SharePoint solutions</h2>
          <p className="text-large">
            At MRS, we focus on impact. We have over 15 years of experience
            helping organizations drive collaboration and productivity, streamline
            operations and modernize their workforce with Microsoft SharePoint
            solutions.
          </p>
        </div>

        <div className="flex flex-wrap -mx-8 mt-gap-large">
          <div className="sm:w-1/2 w-full px-8 pb-8">
            <Icon name="Smartphone" className="mb-4" />
            <h3>Intranets</h3>
            <p>
              Drive greater collaboration and create a modern, digital workplace
              with custom SharePoint intranet portals.
            </p>
            <ul>
              <li>
                Leverage modern SharePoint/Microsoft 365 features and capabilities
              </li>
              <li>Share company news, updates and announcements</li>
              <li>Improve remote working practices</li>
              <li>Reflect your company branding and design</li>
            </ul>
            <p>Drive results in:</p>
            <div className="flex flex-wrap mt-2">
              <Tag content="Collaboration" className="mr-2 mb-2" />
              <Tag content="Employee experience" className="mr-2 mb-2" />
              <Tag content="Remote working" className="mr-2 mb-2" />
            </div>
          </div>
          <div className="sm:w-1/2 w-full px-8 pb-8">
            <Icon name="Archive" className="mb-4" />
            <h3>Information management</h3>
            <p>
              Manage enterprise content more effectively and harness
              organizational knowledge with SharePoint content management
              solutions.
            </p>
            <ul>
              <li>
                Increase security with document policies and approval workflows
              </li>
              <li>Drive productivity </li>
              <li>Store content in a trusted, secure cloud platform</li>
            </ul>
            <p>Drive results in:</p>
            <div className="flex flex-wrap mt-2">
              <Tag content="Information architecture" className="mr-2 mb-2" />
              <Tag content="Workplace compliance" className="mr-2 mb-2" />
              <Tag content="Productivity" className="mr-2 mb-2" />
            </div>
          </div>
          <div className="sm:w-1/2 w-full px-8 pb-8">
            <Icon name="Map" className="mb-4" />
            <h3>Workflow Automation</h3>
            <p>
              Streamline business processes, automate tedious tasks and enforce
              best practices with SharePoint workflow solutions.
            </p>
            <ul>
              <li>
                Leverage powerful tools such as SPFX, K2, PowerApps and more.
              </li>
              <li>Keep detailed audit trails</li>
              <li>Enforce strict adherence to procedures and best practices</li>
              <li>
                Mobilize your workforce with cloud-based mobile workflow apps
              </li>
            </ul>
            <p>Drive results in:</p>
            <div className="flex flex-wrap mt-2">
              <Tag content="Business process efficiency" className="mr-2 mb-2" />
              <Tag content="Workplace compliance" className="mr-2 mb-2" />
              <Tag content="Employee experience" className="mr-2 mb-2" />
            </div>
          </div>
          <div className="sm:w-1/2 w-full px-8 pb-8">
            <Icon name="Activity" className="mb-4" />
            <h3>Integrations</h3>
            <p>
              Integrate your other business systems with SharePoint to take your
              modern workplace experience to the next level.
            </p>
            <ul>
              <li>ERP systems</li>
              <li>
                Microsoft Teams to unite content management and real-time
                communication
              </li>
              <li>Microsoft Power BI, Tableau and other BI/analytics systems</li>
              <li>APIs</li>
            </ul>
            <p>Drive results in:</p>
            <div className="flex flex-wrap mt-2">
              <Tag content="Modern workplace apps" className="mr-2 mb-2" />
              <Tag content="Employee experience" className="mr-2 mb-2" />
              <Tag content="IT infrastructure" className="mr-2 mb-2" />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default Solutions
