import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'
import ContactHero from '@components/ContactHero'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/h023.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <ContactHero
      image={data.image.childImageSharp.fluid}
      headline="Toronto SharePoint Consulting Services"
      subheading={
        <>
          Unleash the full potential of SharePoint with Toronto's most trusted and
          experienced SharePoint solutions provider.
          <ul>
            <li>End-to-end SharePoint services</li>
            <li>15 years of SharePoint experience</li>
            <li>Certified Microsoft Gold Partner</li>
          </ul>
        </>
      }
      contactFormHeading="Get SharePoint help now"
      contactFormSubheading="We will get back to you in a hurry."
    />
  )
}
export default Hero
