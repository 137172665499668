import React from 'react'

import {
  ClientSuccess,
  CTA1,
  CTA2,
  Hero,
  Highlights,
  Services,
  Solutions,
  MicrosoftPartnerSection,
} from '@components/pages/lp/sharepoint-services-toronto'
import Head from '@components/Head'
import { Logos } from '@components/pages/lp/sharepoint-consulting'
import MoreLinksSection from '@components/pages/lp/MoreLinksSection'

const SharePointServicesLp = () => {
  return (
    <>
      <Head
        title="SharePoint Services Toronto"
        description="MRS offers end-to-end SharePoint services; SharePoint consulting, SharePoint development, SharePoint upgrades and migrations, SharePoint managed services and more."
      />
      <Hero />
      <Logos />
      <Services />
      <CTA1 />
      <Solutions />
      <MicrosoftPartnerSection />
      <ClientSuccess />

      <CTA2 />
      <MoreLinksSection />
    </>
  )
}
export default SharePointServicesLp
