import { Container, Section } from '@components/common'
import ContactForm from '@components/ContactForm'
import { Button } from '@mrs-ui'
import React from 'react'

const CTA2 = () => {
  return (
    <Section verticalSpacing="xl">
      <Container>
        <div className="md:w-2/3 w-full">
          <h2>
            Let's talk about how you can get more out of SharePoint for your
            business
          </h2>
          <p className="text-large">
            Reach out to us to have a discussion with our SharePoint experts and
            to find out how we can help your business.
          </p>
        </div>
        <div className="mt-gap-xl md:w-3/5 mx-auto">
          <ContactForm />
        </div>
      </Container>
    </Section>
  )
}
export default CTA2
