import { Container } from '@components/common'
import Button from '@mrs-ui/Button'
import React from 'react'

const CTA1 = () => {
  return (
    <section className="bg-shade50 py-gap-large">
      <Container>
        <div className="mb-gap-compact md:w-4/5">
          <h2>Need help getting started? We're here to answer any questions.</h2>
          <p className="text-large">
            Our team is eager to help you address your SharePoint challenges.
            Reach out to us today to discuss with a SharePoint expert.
          </p>
        </div>
        <Button appearance="primary" link="/contact" className="mr-4">
          Contact us today
        </Button>
        <Button appearance="default" link="/about">
          Learn more about MRS
        </Button>
      </Container>
    </section>
  )
}
export default CTA1
