import { Container, Section } from '@components/common'
import Icon from '@mrs-ui/Icon'
import React from 'react'

const Services = () => {
  return (
    <Section>
      <Container>
        <div className="md:w-2/3">
          <h2>Benefit from our end-to-end SharePoint services</h2>
          <p className="text-large">
            Our goal is to help you make the most of SharePoint. In order to truly
            unleash the full power and potential of SharePoint, you need a partner
            with a proven track record of success.
          </p>
        </div>

        <div className="flex flex-wrap -mx-6 mt-gap-large">
          <div className="sm:w-1/3 w-full px-6 pb-12">
            <Icon name="Layers" className="mb-4" />
            <h3>SharePoint Consulting</h3>
            <p>
              Our certified SharePoint experts can help your organization identify
              a path to SharePoint success. We can help you:
            </p>
            <ul>
              <li>Discover the benefits of SharePoint for your business</li>
              <li>Develop a roadmap and onboarding strategy</li>
              <li>
                Identify value-added services to maximize your SharePoint
                investments
              </li>
              <li>
                Identify how SharePoint can work with your existing business
                systems
              </li>
            </ul>
          </div>
          <div className="sm:w-1/3 w-full px-6 pb-12">
            <Icon name="Code" className="mb-4" />
            <h3>SharePoint Development</h3>
            <p>
              We can help your business build and deploy custom SharePoint
              solutions and applications. Our development team has unparalleled
              expertise with the SharePoint Framework (SPFX), ReactJS and .NET, as
              well as lowcode dev tools such as PowerApps, K2 and Nintex.
            </p>
            <ul>
              <li>Custom workflow solutions</li>
              <li>Custom intranet portal design and development </li>
              <li>Custom SharePoint site/subsite templates</li>
              <li>SharePoint-based webapps</li>
            </ul>
          </div>
          <div className="sm:w-1/3 w-full px-6 pb-12">
            <Icon name="UploadCloud" className="mb-4" />
            <h3>SharePoint Migrations</h3>
            <p>
              We can help you transform to a modern SharePoint environment with
              end-to-end SharePoint migration services. Our team has unparalleled
              experience transferring significant workloads to SharePoint and can
              help you:
            </p>
            <ul>
              <li>Upgrade to a newer, more modern version of SharePoint</li>
              <li>Migrate to the cloud from on-prem solutions</li>
              <li>Transfer from other cloud providers to SharePoint</li>
            </ul>
          </div>
          <div className="sm:w-1/3 w-full px-6 pb-8">
            <Icon name="Users" className="mb-4" />
            <h3>SharePoint Support and Managed Services</h3>
            <p>
              Leverage SharePoint to it's fullest potential without the need to
              onboard staff and monitor and manage IT infrastructure. We provide:
            </p>
            <ul>
              <li>On-demand helpdesk support</li>
              <li>User-training</li>
              <li>Maintenance and systems analysis</li>
              <li>User onboarding support and guidance</li>
            </ul>
            <p>
              ...all at a service-level agreement (SLA) tailored to your resource
              requirements!
            </p>
          </div>
          <div className="sm:w-1/3 w-full px-6 pb-8">
            <Icon name="CheckSquare" className="mb-4" />
            <h3>SharePoint Health Check</h3>
            <p>
              We can help you analyze and assess your organization's SharePoint
              environment to help identify potential issues and areas for
              innovation. Together, we can help you:
            </p>
            <ul>
              <li>Target and address security issues</li>
              <li>Identify and address compliance issues</li>
              <li>Solve performance issues</li>
              <li>Identify ways to maximize ROI</li>
            </ul>
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default Services
