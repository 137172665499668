import { Container } from '@components/common'
import ContactForm from '@components/ContactForm'
import React from 'react'
import { ContactHeroProps } from './ContactHero.types'
import Img from 'gatsby-image'
import './styles.scss'

const ContactHero: React.FC<ContactHeroProps> = ({ ...props }) => {
  return (
    <section>
      <div className="contact-hero-container">
        <div className="contact-hero-container__inner">
          <Container>
            <div className="flex flex-wrap -mx-8 pb-gap-xl">
              <div className="contact-hero-content w-full md:w-1/2 px-8">
                <div>
                  <h1>{props.headline}</h1>
                  <p className="text-large">{props.subheading}</p>
                </div>
              </div>
              <div className="md:w-1/2 w-full md:px-8">
                <div className="bg-base md:p-10 p-4">
                  <h3 className="mb-4">{props.contactFormHeading}</h3>

                  <ContactForm autofocus />
                </div>
              </div>
            </div>
          </Container>
          <div className="contact-hero-image-container md:block hidden">
            <div className="contact-hero-image-spacer" />
            <Img fluid={props.image} style={{ height: '376px' }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactHero
