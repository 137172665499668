import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'
import Img from 'gatsby-image'

const MicrosoftPartnerSection = () => {
  const data = useStaticQuery(graphql`
    query {
      analyticsImage: file(
        relativePath: { eq: "images/logos/partners/ms_gold_logo-main.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Section verticalSpacing="xl" className="bg-shade50">
        <Container>
          <div className="flex flex-wrap -mx-10">
            <div className="md:w-1/2 w-full px-10  mb-4">
              <Img
                fluid={data.analyticsImage.childImageSharp.fluid}
                style={{ minHeight: '360px' }}
              />
            </div>
            <div className="md:w-1/2 w-full px-10 mb-4">
              <h2 className="mb-gap-compact">
                MRS: your trusted Microsoft partner
              </h2>
              <p className="mb-gap-compact">
                With over 40 years of IT experience and a decades-long partnership
                with Microsoft, MRS is Toronto's most trusted, experienced
                Microsoft partner and Microsoft Cloud Solutions Provider.
              </p>
              <p className="mb-gap-compact">
                We're certified partners for Collaboration and Content (SharePoint
                and Office 365), Cloud Platform (Azure), Messaging (Microsoft
                Teams) and more.
              </p>
              <p>
                <Button appearance="link" link="/contact" className="mr-gap-mini">
                  Get started today
                </Button>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}
export default MicrosoftPartnerSection
